import React, { Fragment } from 'react'
import {
  $getSelection,
  $isNodeSelection,
  $isRangeSelection,
  LexicalEditor,
  $createParagraphNode,
} from 'lexical'
import { Listbox, Transition, TransitionChild } from '@headlessui/react'
import clsx from 'clsx'
import {
  $createHeadingNode,
  $createQuoteNode,
  HeadingTagType,
} from '@lexical/rich-text'
import {
  PiListBulletsBold,
  PiListChecksBold,
  PiListNumbersBold,
  PiParagraph,
  PiQuotesBold,
} from 'react-icons/pi'
import { $setBlocksType } from '@lexical/selection'
import {
  INSERT_CHECK_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from '@lexical/list'
import {
  LuHeading1,
  LuHeading2,
  LuHeading3,
  LuHeading4,
  LuHeading5,
  LuHeading6,
} from 'react-icons/lu'

export const blockTypeToBlockName = {
  paragraph: 'Normal',
  bullet: 'Bulleted List',
  h1: 'Heading 1',
  h2: 'Heading 2',
  h3: 'Heading 3',
  h4: 'Heading 4',
  h5: 'Heading 5',
  h6: 'Heading 6',
  number: 'Numbered List',
  quote: 'Quote',
}

export const blockTypeNames = [
  'paragraph',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'number',
  'bullet',
  'quote',
]

const getIcon = (name: string) => {
  if (name === 'paragraph') {
    return <PiParagraph className="text-gray-700 w-3.5 h-3.5" />
  }
  if (name === 'h1') {
    return <LuHeading1 className="text-gray-700 w-3.5 h-3.5" />
  }
  if (name === 'h2') {
    return <LuHeading2 className="text-gray-700 w-3.5 h-3.5" />
  }
  if (name === 'h3') {
    return <LuHeading3 className="text-gray-700 w-3.5 h-3.5" />
  }
  if (name === 'h4') {
    return <LuHeading4 className="text-gray-700 w-3.5 h-3.5" />
  }
  if (name === 'h5') {
    return <LuHeading5 className="text-gray-700 w-3.5 h-3.5" />
  }
  if (name === 'h6') {
    return <LuHeading6 className="text-gray-700 w-3.5 h-3.5" />
  }
  if (name === 'number') {
    return <PiListNumbersBold className="text-gray-700 w-3.5 h-3.5" />
  }
  if (name === 'bullet') {
    return <PiListBulletsBold className="text-gray-700 w-3.5 h-3.5" />
  }
  if (name === 'quote') {
    return <PiQuotesBold className="text-gray-700 w-3.5 h-3.5" />
  }
}

interface BlockFormatMenuProps {
  editor: LexicalEditor
  blockType: keyof typeof blockTypeToBlockName
}

const BlockFormatMenu = React.forwardRef<HTMLDivElement, BlockFormatMenuProps>(
  ({ editor, blockType }, ref) => {
    const formatHeading = (headingSize: HeadingTagType) => {
      if (blockType !== headingSize) {
        editor.update(() => {
          const selection = $getSelection()
          if ($isRangeSelection(selection) || $isNodeSelection(selection)) {
            $setBlocksType(selection, () => $createHeadingNode(headingSize))
          }
        })
      }
    }

    const handleChange = (value: string) => {
      switch (value) {
        case 'paragraph':
          editor.update(() => {
            const selection = $getSelection()
            if ($isRangeSelection(selection) || $isNodeSelection(selection)) {
              $setBlocksType(selection, () => $createParagraphNode())
            }
          })
          break
        case 'h1':
        case 'h2':
        case 'h3':
        case 'h4':
        case 'h5':
        case 'h6':
          formatHeading(value)
          break
        case 'number':
          if (blockType !== 'number') {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
          } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
          }
          break
        case 'bullet':
          if (blockType !== 'bullet') {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
          } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
          }
          break
        case 'quote':
          if (blockType !== 'quote') {
            editor.update(() => {
              const selection = $getSelection()
              if ($isRangeSelection(selection) || $isNodeSelection(selection)) {
                $setBlocksType(selection, () => $createQuoteNode())
              }
            })
          }
          break
      }
    }

    return (
      <Listbox value={blockType} onChange={handleChange}>
        {({ open, value }) => (
          <div className="relative z-50" ref={ref}>
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 dark:text-slate-400 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 flex justify-start items-center gap-2">
              {getIcon(blockType)}
              <span className="block truncate">
                {blockTypeToBlockName[blockType]}
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={React.Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute mt-1 max-h-60 w-auto overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm no-scrollbar">
                {blockTypeNames.map((name: string) => (
                  <Listbox.Option
                    key={name}
                    className={({ active }) =>
                      clsx(
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900 dark:text-slate-400',
                        'relative cursor-default select-none py-2 pl-4 px-8',
                      )
                    }
                    value={name}
                  >
                    {({ selected, active }) => (
                      <div className="flex justify-start items-center gap-2">
                        {getIcon(name)}
                        <span
                          className={clsx(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate',
                          )}
                        >
                          {blockTypeToBlockName[name]}
                        </span>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        )}
      </Listbox>
    )
  },
)

export default BlockFormatMenu
